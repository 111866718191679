export const DAC_AUTH_LOGIN         = 'api/auth/login';
export const DAC_AUTH_SENDOTP       = 'api/auth/sendOtp';
export const DAC_AUTH_VALIDATEOTP   = 'api/auth/validateOtp';
export const DAC_AUTH_REFRESH_TOKEN = 'api/auth/refreshToken';
export const DAC_AUTH_UPDATEPWD     = 'api/auth/resetPassword';
export const DAC_USER_LIST          = 'api/user/list';
export const DAC_USER_GET           = 'api/user/list/';
export const DAC_USER_SAVE          = 'api/user/create';
export const DAC_USER_UPDATE        = 'api/user/update/';
export const DAC_ROL_LIST           = 'api/rol/list';
export const DAC_BIRTHDAY_LIST      = 'api/px/birthday';
export const DAC_PX_SEARCHER        = 'api/px/search';
export const DAC_FAMILY_PATH        = 'api/px/family-path';
export const DAC_PX_SAVE            = 'api/px/save';
export const DAC_PX_UPDATE          = 'api/px/update';
export const DAC_PX_BY_ID           = 'api/px/get/';
export const DAC_PX_DELETE          = 'api/px/delete';
export const DAC_PATOLOGIC_SECTION  = 'api/catalog/SectionsPathological';
export const DAC_PATOLOGICAL        = 'api/catalog/Pathological';
export const DAC_FILE               = 'api/catalog/Files';
export const DAC_FILE_PROFILE       = 'api/file/px/upload-profile';
export const DAC_FILE_PHOTO         = 'api/file/px/upload-file';
export const DAC_GET_PHOTOS         = 'api/file/px/file/';
export const DAC_DELETE_PHOTO       = 'api/file/delete';
export const DAC_ADD_MONITORING     = 'api/tracing/save';
export const DAC_UPD_MONITORING     = 'api/tracing/UPDATE';
export const DAC_GET_MONITORING     = 'api/tracing/list/';
export const DAC_DELETE_MONITORING  = 'api/tracing/delete';
export const DAC_GET_ALL_MONITORING = 'api/tracing/next';
export const DAC_ODONTOGRAM_GET     = 'api/teeth/list/';
export const DAC_CONDITIONS_GET     = 'api/catalog/teeth/condition';
export const DAC_ADD_OTREATMENT     = 'api/treatment/save';
export const DAC_UPD_OTREATMENT     = 'api/treatment/update';
export const DAC_GET_OTREATMENT     = 'api/treatment/list/';
export const DAC_GET_PX_TREATMENT   = 'api/treatment/cat/list/';
export const DAC_DELETE_OTREATMENT  = 'api/treatment/delete';
export const DAC_ODONTOGRAM_GET_TOOTH   = 'api/odontogram/get/tooth/';
// export const DAC_ODONTOGRAM_SAVE        = 'api/odontogram/save';
export const DAC_ODONTOGRAM_UPDATE      = 'api/odontogram/update';
export const DAC_CONFIG_SAVE        = "/api/config/signatureconten/save";
export const DAC_CONFIG_GET          = "/api/config/signatureconten/get";
export const DAC_CONFIG_UPDATE       = "/api/config/signatureconten/update";
export const DAC_ODONTOGRAM_TYPE_TOOTH  = "/api/odontogram/getcatypetooth";
export const DAC_TRATAMENT_GET  = "/api/treatment/diagnosis/get/";
export const DAC_TRATAMENT_SAVED  = "/api/treatment/diagnosis/save";

export const DAC_ODONTOGRAM_VALIDATE     = 'api/odontogram/v1/validate/';
export const DAC_ODONTOGRAM_PARENT_SAVE     = 'api/odontogram/v1/save';
export const DAC_ODONTOGRAM_DETAIL_SAVE     = 'api/odontogram/v1/detail';
export const DAC_ODONTOGRAM_DETAIL_GET     = 'api/odontogram/v1/odontogram/';
export const DAC_ODONTOGRAM_HISTORY_GET     = 'api/odontogram/v1/history/';
export const DAC_ODONTOGRAM_STATUS      = 'api/odontogram/v1/status';
export const DAC_ODONTOGRAM_DELETE      = 'api/odontogram/v1/delete';