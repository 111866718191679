<template>
  <div class="h-full bg-center bg-cover" :style="`background-image: url('`+bgImage+`')`">
    <header class="fixed overflow-hidden top-0 inset-x-0 z-50 shadow-md">
    <nav class="bg-white flex justify-between align-middle  py-2 px-2 ">
    <div class="flex self-center align-middle justify-self-center">
      <v-menu offset-y >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mr-2" icon v-bind="attrs" v-on="on" color="brown lighten-1">
          <v-icon>mdi-apps</v-icon>
        </v-btn>
      </template>
      <v-card min-width="300" class="" color="brown lighten-5">
        <div class="bg-popMenu-200 rounded-md m-2">
          <div class="grid grid-rows-2 grid-cols-3 gap-0">
            <div v-for="(item, index) in menuItem" :key="index"
              class="ml-2  hover:text-gray-700 hover:bg-slate-200 rounded-3xl h-20 w-24 flex flex-col justify-center items-center hover:shadow-lg cursor-pointer" @click="$router.push(item.url)">
              <v-icon size="30">{{item.icono }}</v-icon>
              <span class="text-center text-sm mt-2">{{ item.titulo }}</span>
            </div>
            <div
              class="ml-2  hover:text-gray-700 hover:bg-slate-200 rounded-3xl h-20 w-24 flex flex-col justify-center items-center hover:shadow-lg cursor-pointer" @click="onLogout">
              <v-icon size="30">mdi mdi-logout</v-icon>
              <span class="text-center text-sm mt-2">Salir</span>
            </div>
          </div>
        </div>
      </v-card>
    </v-menu>
      
    </div>
    <div>
          <div class="sm:h-14 lg:h-14 sm:w-48 lg:w-48 ">
            <div class="h-full w-auto bg-contain bg-center" :style="`background-image: url('`+logoOrrico+`')`"></div>
          </div>
    </div>
    </nav>
    </header>
        
    <div v-if="$route.meta.headertitle" class="py-3 flex justify-center mt-20">
        <label class="text-terciary-500 font-sans font-normal sm:text-xl lg:text-3xl tracking-wider">{{ $route.meta.headertitle ? $route.meta.headertitle : '' }}</label>
    </div>
        
    <da-re-login :show="isRelogin" v-on:auth="isRelogin = false"></da-re-login>
    <div class="mt-10 pb-16">
      <slot></slot>
    </div>

    <div class="absolute inset-x-0 bottom-0 text-center">
  <div class="h-11 w-full bg-gray-200 rounded-t-sm">
    <div class="h-11 grid grid-cols-2 items-center gap-x-0">
      <div class="text-left">
        <span class="ml-2 text-sm font-medium">Copyright © Todos los derechos reservados</span>
      </div>

      <div class="flex justify-end">
        <span class="text-base font-medium underline underline-offset-8 cursor-pointer text-primary-900 hover:text-primary-1000 mr-2" @click="$router.push('/privacity')">
          Aviso de privacidad
        </span> 
      </div>
    </div>
  </div>
</div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import fnTypes from '../../store/modules/common/fn-types';
import moduleTypes from '../../store/modules/common/module-types';
import ReLogin from '../../views/Auth/ReLogin.vue';
import VuexMixin from '../../mixins/VuexMixin';

export default {
    mixins:[VuexMixin],
    data: function() {
        return {
          menuItem: [
                    { icono: "mdi-home", titulo: "Inicio",url: '/' },
                    { icono: "mdi-account-multiple-plus", titulo: "Agregar PX",url: '/px/new' },
                    { icono: "mdi-account-multiple", titulo: "Pacientes", url: '/px/all' },
                    { icono: "mdi-calendar", titulo: "Citas", url: '/px/dates' },
                    { icono: "mdi mdi-cogs", titulo: "Configuración", url: '/admin' }
            ],
            bgImage:    require('@/assets/login/doBackGround.jpg'),
            logoOrrico: require('@/assets/login/doLogotypeSmall.png'),
            openMenu: false,
            isRelogin: false
        }
    },
    components: {
      'da-re-login': ReLogin
    },
    computed: {
        ...mapGetters(moduleTypes.AUTH, {
            isAuthorized: fnTypes.isAuthorized,
            user: fnTypes.getUser,
            unauthorized: fnTypes.unauthorized,
            refreshtoken: fnTypes.refreshtoken,
            isRefresingToken: fnTypes.isRefresingToken,
        }),
        currentRoute: function() {
          return this.$route.name
        }
    },

    methods: {
        ...mapActions(moduleTypes.AUTH, [
            fnTypes.logout, fnTypes.getRefreshtoken
        ]),
        onLogout: async function() {
            await this.logout();
            if(!this.isAuthorized) {
                this.$router.push('/auth/login')
            }
        },
        onBlur: function() {
          console.log('BLUR');
          
        }
    },
    watch: {
      currentRoute: function() {
          this.openMenu = false;
      },
      unauthorized: async function() {
        if(this.unauthorized && this.refreshtoken) {
          await this.getRefreshtoken()
        }else {
          if(this.isRefresingToken == false && !this.refreshtoken) {
              this.isRelogin = true;
          }
        }
      }
    }
}
</script>

<style >
.header, .v-toolbar__content {
    align-items: center;
    display: flex;
    position: relative;
    z-index: 0;
    width: 100%;
}

.v-dialog {
  border-radius: 0px;
  margin: 0px;
  overflow-y: auto;
  pointer-events: auto;
  transition: .3s cubic-bezier(.25,.8,.25,1);
  width: 100%;
  z-index: inherit;
  outline: none;
}

</style>

<style scoped>

.v-dialog {
  box-shadow: 0 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%)
}

.v-dialog__content {
  align-items: start;
  margin-top: 3.2rem;
}

</style>