import { DAC_ODONTOGRAM_GET_TOOTH, DAC_ODONTOGRAM_DETAIL_SAVE, DAC_ODONTOGRAM_UPDATE, DAC_ODONTOGRAM_TYPE_TOOTH, DAC_ODONTOGRAM_VALIDATE, DAC_ODONTOGRAM_PARENT_SAVE, DAC_ODONTOGRAM_DETAIL_GET, DAC_ODONTOGRAM_HISTORY_GET, DAC_ODONTOGRAM_STATUS, DAC_ODONTOGRAM_DELETE} from '../../common/apiurl';
import { DACore } from '../../config/axios.context'

export default {

    /**
     * Función que permite obtener el odontograma
     * 
     * @returns 
     */
    // async getOdontogram(id, code) {
    //     try {
    //         return DACore.get(DAC_ODONTOGRAM_GET + id + (code ? '/'+code : ''));
    //     } catch (error) {
    //         return error.response.data;
    //     }
    // },

    /**
     * Función que permite obtener los datos de un diente
     * @param {*} id  ID del diente
     * @param {*} id_person ID del PX
     * @returns 
     */
    async getTooth(id, id_person) {
        try {
            return DACore.get(DAC_ODONTOGRAM_GET_TOOTH + id_person + '/' + id);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite guardar los datos y configuración de un diente
     * @param {*} data Modelo de los dientes
     * @returns 
     */
    async save(data) {
        try {
            return DACore.post(DAC_ODONTOGRAM_PARENT_SAVE, data);
        } catch (error) {
            return error.response.data;
        }
    },

    async saveDetail(data) {
        try {
            return DACore.post(DAC_ODONTOGRAM_DETAIL_SAVE, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Funcion que permite actualizar los datos de dien te y su config
     * @param {*} data  Modelo de los dientes
     * @returns 
     */
    async update(data) {
        try {
            return DACore.put(DAC_ODONTOGRAM_UPDATE, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite obtener el catalogo de tipos de odontogramas
     * @returns 
     */
    async getTypeTooth() {
        try {
            return DACore.get(DAC_ODONTOGRAM_TYPE_TOOTH);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Servicio que permite validar si un usuario cuenta o no con odontograma
     * @param {*} id 
     * @returns 
     */
    async validateOdontogram(id) {
        try {
            return DACore.get(DAC_ODONTOGRAM_VALIDATE + id);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Servicio qe pemite obtener los detalle de odontogama
     * @param {*} id Identificador del px
     * @returns 
     */
    async getOdontogramDetail(id) {
        try {
            return DACore.get(DAC_ODONTOGRAM_DETAIL_GET + id,);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Servicio que permite obtener el historial de odontogramas
     * @param {*} id ID del px
     * @returns 
     */
    async getHistory(id) {
        try {
            return DACore.get(DAC_ODONTOGRAM_HISTORY_GET + id,);
        } catch (error) {
            return error.response.data;
        }
    },

    async status(data) {
        try {
            return DACore.put(DAC_ODONTOGRAM_STATUS, data);
        } catch (error) {
            return error.response.data;
        }
    },

    async delete(data) {
        try {
            return DACore.delete(DAC_ODONTOGRAM_DELETE, data);
        } catch (error) {
            return error.response.data;
        }
    },
}