const getters = {
    odontogram: state => state.odontogram,
    getTooth: state => state.tooth,
    hasError: state => state.hasError,
    getTypeTooth: state => state.typeTooth,
    codetypetooth: state => state.codetypetooth,
    id_odontogram: state => state.id_odontogram,
    odontogramDetail: state => state.odontogramDetail,
    history: state => state.history,
    odontogramEdit: state => state.odontogramEdit,
}

export default getters